export const COPY_TEXT =
  "Background Music: [Paste Artist + Tracktitle]\n" +
  "© Provided by Audio Sanctum\n" +
  "Watch: [Paste link to Audio Sanctum YT video]\n" +
  "Listen: https://spotify.audiosanctum.com\n" +
  "Buy: https://sanctum.shop\n" +
  "Free Use Terms → https://audiosanctum.com/free-use";

export const HEADING_FONT_WEIGHT = 500;
export const TEXT_FONT_WEIGHT = 400;
