import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import GradientDialog from "@/components/Dialog";
import { DescriptionTypography, MainTextTypography } from "@/pages/components";

interface IDialog {
  open: boolean;
  handleClose: () => void;
}

const Dialog: FC<IDialog> = ({ open, handleClose }) => {
  const { t } = useTranslation("homePage", { keyPrefix: "newsletter.dialog" });
  return (
    <GradientDialog
      open={open}
      handleClose={handleClose}
      sx={{
        "&&": {
          ".MuiDialog-paper > div > div:last-child": {
            paddingBottom: "11px",
          },
        },
      }}
    >
      <Stack spacing="40px" height="100%" width="100%" justifyContent="center" alignItems="center">
        <Stack spacing="24px" justifyContent="center" alignItems="center">
          <MainTextTypography $isHighlighted textAlign="center">
            {t("mainText")}
          </MainTextTypography>
          <DescriptionTypography textAlign="center">
            <Trans t={t} i18nKey="description" />
          </DescriptionTypography>
        </Stack>

        <GradientButton isOutlined onClick={handleClose}>
          {t("buttonText")}
        </GradientButton>
      </Stack>
    </GradientDialog>
  );
};

export default Dialog;
