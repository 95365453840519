import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Box, Slider, Stack } from "@mui/material";

import {
  CopyrightIcon,
  DownloadGoldIcon,
  LicenseRequestIcon,
  LikeFilledIcon,
  LikeOutlinedIcon,
  VolumeMutedIcon,
  VolumeSliderIcon,
} from "@assets/svg";

import { AvailableFor, TPlayerProps } from "@components/BottomPlayer/components/types";
import * as Styled from "@components/BottomPlayer/styles";
import { ShareMenu } from "@components/ShareMenu";
import { ERoutes } from "@utils/constants";
import { formatTimePlayer } from "@utils/formatTimePlayer";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

import { Button, IconButton, PlayButton } from "@/ui-kit";

import { EWaveSurferPlayer, getSkeleton, MAX_VOLUME, TSkeletons } from "../../utils";

export const DesktopLayout: FC<TPlayerProps> = ({
  state,
  bottomTrackData,
  currentTimeRef,
  handleNavigate,
  handleLicensing,
  handleNavigateComposer,
  handleDownload,
  handleVolumeMute,
  isVolumeTouched,
  onVolumeChange,
  onPlayClick,
  containerRef,
  isAvailableFor,
  dispatchReducer,
  handleLike,
}) => {
  const { t } = useTranslation("downloadModal", { keyPrefix: "bottomPlayer" });
  return (
    <>
      <Stack spacing={"16px"} width="fit-content" direction="row" alignItems="center" marginRight="auto !important">
        {state.isLoading ? (
          getSkeleton(TSkeletons.AlbumCover, true)
        ) : (
          <Styled.AlbumCover
            onClick={() => {
              handleNavigate(ERoutes.Album, bottomTrackData?.albumId, true);
            }}
            alt="Album cover"
            src={bottomTrackData?.smallCoverUrl || bottomTrackData?.largeCoverUrl}
          />
        )}
        <Stack direction="row" spacing="16px" alignItems="center">
          <Stack spacing="4px" width="200px">
            <Styled.SongTitle
              onClick={() => {
                handleNavigate(ERoutes.Track, bottomTrackData?._id);
              }}
            >
              {state.isLoading ? getSkeleton(TSkeletons.TITLE) : bottomTrackData?.name}
            </Styled.SongTitle>
            <Styled.SongAuthor
              onClick={() =>
                handleNavigateComposer(
                  typeof bottomTrackData?.composer[0] === "object"
                    ? bottomTrackData?.composer[0].name
                    : bottomTrackData?.composer[0],
                )
              }
            >
              {state.isLoading
                ? getSkeleton(TSkeletons.COMPOSER)
                : bottomTrackData?.composer.map(
                    (c, i) =>
                      `${c.name ? c.name : c}${
                        bottomTrackData?.composer.length !== 1 && i !== bottomTrackData?.composer.length ? "," : ""
                      }`,
                  )}
            </Styled.SongAuthor>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={"8px"} width="55%">
        <Stack direction="row" spacing="4px" width="100%" alignItems="center">
          <Stack marginRight={"16px !important"}>
            {state.isLoading ? (
              getSkeleton(TSkeletons.PlayButton, true)
            ) : (
              <PlayButton onClick={onPlayClick} isPlaying={state.isPlaying} />
            )}
          </Stack>

          <Styled.TimeDuration
            maxWidth="36px"
            minWidth="36px"
            width="fit-content"
            marginLeft="4px !important"
            ref={currentTimeRef}
          >
            {state.isLoading ? getSkeleton(TSkeletons.TIME) : "00:00"}
          </Styled.TimeDuration>

          <Styled.WaveFormStack width="100%" $isCurrent>
            {state.isLoading && getSkeleton(TSkeletons.LineProgress, true)}
            <Box
              id="waveform"
              ref={containerRef}
              width="100%"
              height="100%"
              sx={{
                display: state.isLoading ? "none" : "block",
                fontFamily: "Kantumruy Pro !important",
              }}
            />
          </Styled.WaveFormStack>
          <Styled.TimeDuration width="fit-content">
            {state.isLoading ? getSkeleton(TSkeletons.TIME) : formatTimePlayer(bottomTrackData?.length)}
          </Styled.TimeDuration>
        </Stack>
        <PopupState disableAutoFocus variant="popover" popupId="volume-popover">
          {(popupState) => (
            <div>
              <IconButton
                key={bottomTrackData?._id}
                isActive={isVolumeTouched}
                isTransparent
                onClick={handleVolumeMute}
                {...bindHover(popupState)}
              >
                {state.isMuted ? <VolumeMutedIcon /> : <VolumeSliderIcon />}
              </IconButton>

              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "rgba(75, 55, 39, 1)",
                    height: "96px",
                    width: "32px",
                    padding: "8px",
                    overflow: "hidden",
                  },
                }}
                disableScrollLock
              >
                <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: "slider-vertical",
                        height: "100%",
                      },
                      ".MuiSlider-thumb:hover": {
                        boxShadow: "none",
                      },
                      ".MuiSlider-rail": {
                        color: "#382a1f",
                      },
                    }}
                    value={state.volume}
                    orientation="vertical"
                    min={0}
                    max={MAX_VOLUME}
                    step={0.1}
                    style={{ height: "72px", padding: 0 }}
                    onChange={onVolumeChange}
                  />
                </Stack>
              </HoverPopover>
            </div>
          )}
        </PopupState>
      </Stack>
      <Stack spacing="8px" width="fit-content" direction="row" alignItems="center" marginLeft="auto !important">
        <IconButton
          isTransparent
          isHeartIcon
          isBottomPlayer
          onClick={handleLike}
          isActive={state.isFavorite}
          sx={{ marginRight: "4px" }}
        >
          {state.isFavorite ? (
            <LikeFilledIcon width="32px" height="32px" />
          ) : (
            <LikeOutlinedIcon width="32px" height="32px" />
          )}
        </IconButton>
        {isAvailableFor(AvailableFor.FREE_USE) ? (
          <>
            <Button isIconButtonLike isOutlined width={118} onClick={handleDownload}>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing="8px">
                <DownloadGoldIcon width="15" height="24" />
                <Stack>{t("download")}</Stack>
              </Stack>
            </Button>
            <IconButton onClick={handleLicensing}>
              <LicenseRequestIcon width="24px" height="24px" />
            </IconButton>
          </>
        ) : (
          <Button isIconButtonLike isOutlined width={118} onClick={handleLicensing}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing="8px">
              <LicenseRequestIcon width="22px" height="24px" />
              <Stack>{t("license")}</Stack>
            </Stack>
          </Button>
        )}
        <IconButton
          onClick={() => {
            dispatchReducer({
              type: EWaveSurferPlayer.IsCopyright,
              payload: true,
            });
            dispatchReducer({
              type: EWaveSurferPlayer.OpenModal,
              payload: !state.openModal,
            });
          }}
        >
          <CopyrightIcon width="24px" height="24px" />
        </IconButton>
        <ShareMenu songId={bottomTrackData?._id} />
      </Stack>
    </>
  );
};
