export const youtube = {
  title: "YouTube",
  links: [
    {
      title: "Choir Sanctum",
      src: "https://www.youtube.com/@choirsanctum",
      type: "choir",
    },
    { title: "Piano Sanctum", src: "https://www.youtube.com/@pianosanctum", type: "piano" },
    {
      title: "Soundtrack Sanctum",
      src: "https://www.youtube.com/@soundtrack-sanctum",
      type: "soundtrack",
    },
  ],
};

export const spotify = {
  title: "Spotify",
  links: [
    { title: "Audio Sanctum", src: "https://open.spotify.com/user/31k5gubg4hn5s6jsbitmbm6yck5q", type: "choir" },
    { title: "Piano Sanctum", src: "https://open.spotify.com/playlist/62a77zIJrDz23DOd3il1Rz", type: "piano" },
    {
      title: "Soundtrack Sanctum",
      src: "https://open.spotify.com/playlist/2mO4YEYrFJFfYREGQy41eg",
      type: "soundtrack",
    },
    { title: "Choir Sanctum", src: "https://open.spotify.com/playlist/0cwkdBJUn5dsfubOkTyo4a", type: "choir" },
    { title: "Chant Sanctum", src: "https://open.spotify.com/playlist/3EjHt7keg2Z4qb69JB6kdm", type: "chant" },
  ],
};

export const soundcloud = {
  title: "Soundcloud",
  links: [
    { title: "Audio Sanctum", src: "https://soundcloud.com/audiosanctum", type: "choir" },
    { title: "Piano Sanctum", src: "https://soundcloud.com/audiosanctum/sets/piano-sanctum", type: "piano" },
    {
      title: "Soundtrack Sanctum",
      src: "https://soundcloud.com/audiosanctum/sets/soundtrack-sanctum",
      type: "soundtrack",
    },
    { title: "Choir Sanctum", src: "https://soundcloud.com/audiosanctum/sets/choir-sanctum", type: "choir" },
    { title: "Chant Sanctum", src: "https://soundcloud.com/audiosanctum/sets/chant-sanctum", type: "chant" },
  ],
};

export const appleMusic = "https://music.apple.com";
export const bandcamp = "https://audiosanctum.bandcamp.com/";
