import { FC, useEffect, useRef, useState } from "react";

import GradientDialog from "../Dialog";
import * as Styled from "./styles";
import { renderStepContent, Steps } from "./utils";

type TQuestionFlowDialog = {
  open: boolean;
  onClose: () => void;
};

export const QuestionFlowDialog: FC<TQuestionFlowDialog> = ({ onClose, open }) => {
  const openId = useRef<number>(1);
  const [activeStep, setActiveStep] = useState<number>(Steps.START);

  const handleNextStep = (finalStep?: Steps) => {
    if (!finalStep) {
      return setActiveStep((prevState) => prevState + 1);
    }
    setActiveStep(finalStep);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setActiveStep(Steps.START);
      }, 200);
    }
  }, [open]);

  return (
    <GradientDialog
      key={openId.current}
      open={open}
      handleClose={onClose}
      closeBtnStyles={{
        svg: {
          width: "32px",
          height: "32px",
        },
        transform: `translate(-25%, 25%)`,
      }}
      isNotScrollable={true}
    >
      <Styled.Wrapper>{renderStepContent(activeStep, handleNextStep, onClose)}</Styled.Wrapper>
    </GradientDialog>
  );
};
