import { useTranslation } from "react-i18next";

import DealIcon from "@assets/svg/unauthorized-user-dialog/deal_ico.svg";
import EmailIcon from "@assets/svg/unauthorized-user-dialog/email_ico.svg";
import MusicalNoteIcon from "@assets/svg/unauthorized-user-dialog/musical-note_ico.svg";
import SolidHeartIcon from "@assets/svg/unauthorized-user-dialog/solid-heart_ico.svg";

import { useIsDesktop } from "@hooks/layout";

type TUserAdvantages = {
  icon: JSX.Element;
  title: string;
  description?: string;
};

const titleAndDescription = (advantageName: string) => {
  const { t } = useTranslation("login", { keyPrefix: "unauthorized" });
  const isDesktop = useIsDesktop();
  if (isDesktop) {
    return {
      title: t(`${advantageName}.title`),
      description: t(`${advantageName}.description`),
    };
  }
  return {
    title: t(`${advantageName}.mobileTitle`),
  };
};

export const USER_SIGN_UP_ADVANTAGES = (): TUserAdvantages[] => {
  return [
    {
      icon: <img src={MusicalNoteIcon} alt="music-note" loading="lazy" />,
      ...titleAndDescription("musicDownloads"),
    },
    {
      icon: <img src={EmailIcon} alt="email-notification" loading="lazy" />,
      ...titleAndDescription("releaseNotification"),
    },
    {
      icon: <img src={SolidHeartIcon} alt="favorites" loading="lazy" />,
      ...titleAndDescription("favoritesLibrary"),
    },
    {
      icon: <img src={DealIcon} alt="creator-support" loading="lazy" />,
      ...titleAndDescription("creatorSupport"),
    },
  ];
};
