import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { Menu, NavLink } from "@ui/interactive";

import { SelectArrowDownIcon } from "@assets/svg";

import { isActiveNavItem } from "@layouts/helpers";
import { ERoutes } from "@utils/constants";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { ActiveNavigationWrapper, DropdownTypography, LinkWrapper } from "./components";
import { popover } from "./Popover";

type DesktopDropdownProps = {
  title: string;
  navItems: NavItem[];
  activePopperHandler: React.MutableRefObject<() => void | null>;
};

type NavItem = { route: ERoutes; key: string };

export const DesktopDropdown: FC<DesktopDropdownProps> = ({
  title,

  navItems,
  // activePopperHandler,
}) => {
  const { t } = useTranslation("navigation");

  const location = useLocation();

  const alignerRef = useRef<HTMLParagraphElement>(null);
  const navElems = useRef<HTMLElement[]>([]);

  const [closePopup, setClosePopup] = useState(false);

  const popupState = usePopupState({ variant: "popper", popupId: "dropdown-menu-popover" });

  const isActive = isActiveNavItem(
    location,
    navItems.map(({ route }) => route),
  );

  useEffect(() => {
    if (closePopup) {
      popupState.close();
      setClosePopup(false);
    }
  }, [closePopup]);

  const renderNavItems = ({ route, key }: NavItem) => (
    <LinkWrapper isNotUppercase isSubItem onClick={() => setClosePopup(true)}>
      <NavLink noLineAnimation={true} to={route} end navElems={navElems}>
        {t(key)}
      </NavLink>
    </LinkWrapper>
  );

  return (
    <>
      <ActiveNavigationWrapper {...bindTrigger(popupState)}>
        <DropdownTypography isActive={isActive} ref={alignerRef}>
          {title}
        </DropdownTypography>
        <SelectArrowDownIcon />
      </ActiveNavigationWrapper>

      <Menu
        popupState={popupState}
        paperProps={{
          ...popover,
          sx: {
            ...popover.sx,
            borderTop: "1",
            width: "fit-content",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
        placement="bottom-start"
        offset={[-25, 8]}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "14px 24px" }}>
          {navItems.map(renderNavItems)}
        </Box>
      </Menu>
    </>
  );
};
