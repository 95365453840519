import { Box, Divider, styled } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

type TContentWrapper = {
  $isChoiceStep?: boolean;
};

export const Wrapper = styled(Box)`
  width: 100%;
`;

export const ContentWrapper = styled(Box)<TContentWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $isChoiceStep }) => $isChoiceStep && "row-gap: 24px;"}
`;

export const ChoiceButtonsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const ChoiceButton = styled("button")`
  flex: 1;
  position: relative;
  border: 1px solid #4e4034;
  background: transparent;
  border-radius: 10px;

  cursor: pointer;

  &:active {
    border: 1px solid transparent;
    p {
      z-index: 2;
      color: #3c2d23;
    }
    &::before {
      opacity: 1;
    }
  }

  ${isSmallDesktop} {
    &:hover {
      border: 1px solid transparent;
      p {
        z-index: 2;
        color: #3c2d23;
      }
      &::before {
        opacity: 1;
      }
    }
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
    z-index: 1;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 80px;
  height: 2px;
  z-index: 5;

  background: linear-gradient(
    93deg,
    rgba(186, 144, 92, 0.5) 0.82%,
    #ffd29b 33.73%,
    #ffe0b1 47.65%,
    #ffd29b 61.53%,
    rgba(186, 144, 92, 0.5) 99.48%
  );
`;
