import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";
import { Text } from "@ui/typography";

import { DownloadAlbumIcon, LicenseTestShield } from "@assets/svg";

import { getAlbumLink, getTrackLink, getUserDownloads } from "@store/actions/downloads";
import { EFullActions } from "@store/actions/downloads/enums";
import { TAlbum, TTrack } from "@store/actions/track";
import { useAppDispatch, useAppDispatchUnwrap, useAppSelector } from "@store/hooks";
import { setOptimisticDownloadCount } from "@store/reducers/downloads";
import { getDownloadsSelector } from "@store/selectors/downloads/getDownloadsSelector";
import { getMySelector } from "@store/selectors/getMeSelector";

import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { ERoutes } from "@utils/constants";
import { download, downloadZip } from "@utils/download";

import { useIsDesktop } from "@/hooks/layout";
import { Button, Link } from "@/ui-kit";

import * as Styled from "./styles";

interface IDownloadPageProps {
  handleNext: () => void;
  item?: TAlbum;
  song?: TTrack;
  type: EDownloadModalType;
  name?: string;
  cover?: string;
  handleClose?: () => void;
}

export enum EDownloadModalType {
  Song = "Song",
  Album = "Album",
}

const DownloadPage: React.FC<IDownloadPageProps> = ({ handleNext, item, song, type, name, cover, handleClose }) => {
  const { t } = useTranslation("downloadModal");
  const isDesktop = useIsDesktop();

  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatchUnwrap();

  const syncDispatch = useAppDispatch();

  const { downloadsLimit, tracksCount, albumsCount } = useAppSelector(getDownloadsSelector);
  const { hasWav } = useAppSelector(getMySelector);

  const availableDownloads = downloadsLimit - (type === EDownloadModalType.Album ? albumsCount : tracksCount);

  useEffect(() => {
    dispatch(
      getUserDownloads({
        id: type === EDownloadModalType.Song ? song._id : item._id,
        isLowQuality: true,
        action: type === EDownloadModalType.Song ? EFullActions.DownloadTrack : EFullActions.DownloadAlbum,
      }),
    );
  }, []);

  return (
    <>
      <Stack justifyContent="center" alignItems="center" spacing="24px" width="100%">
        {type === EDownloadModalType.Album ? (
          <Styled.Title>{t("firstStepAlbum", { album: item?.name })}</Styled.Title>
        ) : (
          <Styled.Title>{t("firstStepSong", { song: song?.name })}</Styled.Title>
        )}
        {isDesktop && (
          <Stack width="100%" alignItems={"center"}>
            <Link href={ERoutes.DownloadInstruction}>{t?.("downloadInstruction", { ns: "downloadModal" })}</Link>
          </Stack>
        )}

        {!isDesktop && (
          <GradientButton
            isOutlined
            isFullWidth
            onClick={() => setOpenQuestionFlow(true)}
            sx={{
              paddingInline: "0 !important",
              padding: "10px 16px !important",
              height: "fit-content !important",
              margin: "0 0 16px 0",
              border: "2px solid #5F5146 !important",
              "&::before": {
                background: "transparent !important",
              },
              "&::after": {
                background: "transparent !important",
              },
              background: "transparent !important",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px", justifyContent: "center", color: "inherit" }}>
              <LicenseTestShield />
              <Text size={0.625} sx={{ maxWidth: "100% !important", fontWeight: 500, fontFamily: "Kaisei Opti" }}>
                {t("testButton")}
              </Text>
            </Box>
          </GradientButton>
        )}

        <Styled.AlbumCover src={cover || item?.largeCoverUrl} />
        <Stack
          direction={isDesktop ? "row" : "column"}
          width="100%"
          spacing="24px"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            isFullWidth
            isBigButton
            isDisabled={availableDownloads === 0 || isLoading}
            onClick={async () => {
              setIsLoading(true);
              if (type === EDownloadModalType.Album) {
                try {
                  const result = await dispatch(
                    getAlbumLink({
                      id: item?._id,
                      isLowQuality: true,
                    }),
                  );
                  await downloadZip(result?.lowQualityZipUrl, item.name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: true }));
                    setIsLoading(false);
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              } else {
                try {
                  const result = await dispatch(
                    getTrackLink({
                      id: song?._id,
                      isLowQuality: true,
                    }),
                  );

                  await download(result?.lowQualityFileUrl, name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: false }));
                    setIsLoading(false);
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              }
              handleNext();
            }}
            sx={{ padding: "8px 0 !important" }}
          >
            <DownloadAlbumIcon />
            <Box display={"flex"} gap={"8px"} margin={"0 0 0 8px"}>
              <Trans
                t={t}
                i18nKey="downloadMP3"
                components={[<div className="additional-text" />]}
                values={{ count: availableDownloads }}
              />
            </Box>
          </Button>
          {hasWav && (
            <Button
              isFullWidth
              isBigButton
              isDisabled={availableDownloads === 0 || isLoading}
              onClick={async () => {
                if (type === EDownloadModalType.Album) {
                  setIsLoading(true);
                  try {
                    const result = await dispatch(
                      getAlbumLink({
                        id: item?._id,
                        isLowQuality: false,
                      }),
                    );
                    await downloadZip(result?.zipUrl, item.name).then(() => {
                      syncDispatch(setOptimisticDownloadCount({ isAlbum: true }));
                    });
                  } catch (error) {
                    console.log("Error fetching link:", error);
                  }
                } else {
                  try {
                    const result = await dispatch(
                      getTrackLink({
                        id: song?._id,
                        isLowQuality: false,
                      }),
                    );

                    await download(result?.fileUrl, name).then(() => {
                      syncDispatch(setOptimisticDownloadCount({ isAlbum: false }));
                      setIsLoading(false);
                    });
                  } catch (error) {
                    console.log("Error fetching link:", error);
                  }
                }
                handleNext();
              }}
              sx={{ padding: "8px 0 !important" }}
            >
              <DownloadAlbumIcon />
              <Box display={"flex"} gap={"8px"} margin={"0 0 0 8px"}>
                <Trans
                  t={t}
                  i18nKey="downloadWav"
                  components={[<div className="additional-text" />]}
                  values={{ count: availableDownloads }}
                />
              </Box>
            </Button>
          )}
        </Stack>
      </Stack>
      {!isDesktop && (
        <Stack width="100%" alignItems={"center"} margin={"24px 0 0 0"}>
          <Link href={ERoutes.DownloadInstruction}>{t?.("downloadInstruction", { ns: "downloadModal" })}</Link>
        </Stack>
      )}
      <QuestionFlowDialog
        open={openQuestionFlow}
        onClose={() => {
          handleClose();
          setOpenQuestionFlow(false);
        }}
      />
    </>
  );
};

export default DownloadPage;
