import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { TUseWavesurfer, useWavesurfer } from "@hooks/wafeform";

import { BottomPlayer } from "@components/BottomPlayer";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { useQueryParam } from "use-query-params";

import { getTrack, PlayerContext, setTrack, TBottomPlayer } from "./utils";

export const PlayerLayout: FC<PropsWithChildren> = ({ children }) => {
  useTranslation(["questionFlow", "questionFlowMobile"]);

  const [data, setData] = useState<TBottomPlayer | null>(null);
  const [media, setMedia] = useState<HTMLMediaElement | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openQuestionFlow, setOpenQuestionFlow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mediaControl, setMediaControl] = useState<TUseWavesurfer>({ containerRef });
  useWavesurfer({ ...mediaControl, isTrackPage: true });
  const track = getTrack();

  const [test] = useQueryParam("test");

  const handleSetCurrentSong = (song: TBottomPlayer) => {
    setData(song);
  };

  const handleSetPlay = (isPlaying: boolean) => {
    if (!data?.id) return;
    setData((prevState) => ({ ...prevState, isPlaying }));
  };

  useEffect(() => {
    if (test) {
      setOpenQuestionFlow(true);
    }
  }, []);

  useEffect(() => {
    if (!data) return;

    setTrack(data);
  }, [data?.id]);

  if (data === null && track) {
    setData({ ...track, isPlaying: false });
  }

  const handleCloseTrack = () => {
    setData(null);
    setTrack(null);
    setMedia(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog((prevState) => !prevState);
  };

  const handleMediaControl = (controls: Omit<TUseWavesurfer, "containerRef">) => {
    setMediaControl((prevState) => ({ ...prevState, ...controls }));
  };

  return (
    <PlayerContext.Provider
      value={{
        data,
        setData: handleSetCurrentSong,
        setPlay: handleSetPlay,
        setMedia,
        media,
        openLoginDialog: openDialog,
        setOpenLoginDialog: handleOpenDialog,
        handleMediaControl,
        containerRef,
      }}
    >
      {children}
      {data && <BottomPlayer handleCloseTrack={handleCloseTrack} />}
      <QuestionFlowDialog
        open={openQuestionFlow}
        onClose={() => {
          setOpenQuestionFlow(false);
        }}
      />
    </PlayerContext.Provider>
  );
};
