import { ERoutes } from "@utils/constants";

export const legal = [
  { ns: "legal", key: "terms", to: ERoutes.Conditions },
  { ns: "legal", key: "privacy", to: ERoutes.Privacy },
  { ns: "legal", key: "cookies", to: "" },
];

export const contact = [
  { ns: "contact", key: "musicCreator", to: ERoutes.MUSIC_CREATOR_PROGRAM },
  { ns: "contact", key: "contact", to: ERoutes.Contact },
  { ns: "contact", key: "submit", to: ERoutes.Submit },
];

export const resources = [
  { ns: "resources", key: "freeUseTerms", to: ERoutes.FreeUseTerms },
  { ns: "resources", key: "deals", to: ERoutes.Deals },
  { ns: "resources", key: "downloadInstructions", to: ERoutes.DownloadInstruction },
];

export const accessMobile = [
  { ns: "mobile", key: "deals", to: ERoutes.Deals },
  { ns: "mobile", key: "faq", to: ERoutes.MUSIC_CREATOR_PROGRAM },
  { ns: "mobile", key: "download", to: ERoutes.DownloadInstruction },
  { ns: "mobile", key: "submit", to: ERoutes.Submit },
  { ns: "mobile", key: "contact", to: ERoutes.Contact },
];

export const legalMobile = [
  { ns: "mobile", key: "freeUseTerms", to: ERoutes.FreeUseTerms },
  { ns: "mobile", key: "licensing", to: ERoutes.Licensing },
  { ns: "mobile", key: "terms", to: ERoutes.Conditions },
  { ns: "mobile", key: "privacy", to: ERoutes.Privacy },
  { ns: "mobile", key: "cookies", to: "" },
];
